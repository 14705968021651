import React from 'react'
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';


function ToolTip() {
    const [openTool, setOpenTool] = React.useState(false);

    const handleTooltipClose = () => {
        setOpenTool(false);
    };

    const handleTooltipOpen = () => {
        setOpenTool(true);
    };
    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
                
            <Tooltip
            
            onClose={handleTooltipClose}
            open={openTool}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Want a meme added fren? Submit to support@33calculator.app" 
            arrow
            
            >
            <small onClick={handleTooltipOpen} >submit a meme</small>
            </Tooltip>
               
        </ClickAwayListener>
    )
}

export default ToolTip
