import React, { useEffect, useState } from 'react'
import './App.css'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ToolTip from './ToolTip';

const style = {
  display:'flex',
  flexDirection:'column',
  alignItems:'center',
  justifyContent:'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  outline:'none',
  borderRadius:'5px',
  bgcolor: 'rgb(32, 38, 45)',
  boxShadow: 24,
  p: 4,
  width:'fit-content',
};




function App() {

  const [open, setOpen] =useState(false);

  const handleOpen = (src,isVideo) => {
    setOpen(true)
    setSelectedImg(src)
    if(isVideo){
      setIsVideo(true)
    }
    else{
      setIsVideo(false)

    }
    
  };
  const handleClose = () => setOpen(false);
  const[selectedImg,setSelectedImg]=useState('')
  const[likedState,setLikedState]=useState([])
  const[displayLiked,setDisplayLiked]=useState(false)
  const[isVideo,setIsVideo]=useState(true)

  function importAll(r) {
    let images = {};
    r.keys().map((item) => { images[item.replace('./', '')] = r(item);  return item;});
    return images;
  }
  
  const images = importAll(require.context('./assets/vault', false));
  

  useEffect(()=>{

    if(getCookie('likedMemes')===""){
      document.cookie = "likedMemes=[]";
    }
    else{
      let likedMemes=JSON.parse(getCookie('likedMemes'));
      setLikedState(likedMemes);
    }
   

  },[])

  function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }


  const likeMeme=(name)=>{

    let likedMemes=JSON.parse(getCookie('likedMemes'));
    likedMemes.push({name:name});
    setLikedState(likedMemes);
    document.cookie=`likedMemes=${JSON.stringify(likedMemes)}`;

  }
  
  const dislikeMeme=(name)=>{
  
    let likedMemes=likedState.filter((meme)=>{return meme.name!==name});
    setLikedState(likedMemes);
    document.cookie=`likedMemes=${JSON.stringify(likedMemes)}`;

  }
  
  return (
    <div className='App'>

      <div className='AppHeader'>
        <div className='AppHeader-right'>
          <span className='App-title'>FrogNation Memes</span>
          <span className='AppHeader-linkHolder'>
            <ToolTip/>
            <small className='calculator-link' onClick={()=>{window.location.href='https://33calculator.app'}}>33calculator app</small>
          </span>
        </div>
       
        <IconButton onClick={()=>{setDisplayLiked(!displayLiked)}} sx={{ color: 'white' }}>
          {displayLiked?
            <FavoriteIcon/>:
            <FavoriteBorderIcon/>
          }
        </IconButton>
      </div>
      <div className='App-inner'>
        {Object.entries(images).filter((data)=>{
          let name=data[0];
          if(displayLiked===false){
            return data
          }
          else{
            for(let i=0;i<likedState?.length;i++){
              if(likedState[i]?.name===name){
                return data
              }
              
            }
          }
          return null
        }).map((data)=>{

          let imageSrc=data[1].default;
          let key=imageSrc+Math.random();
          let name=data[0];
          let liked=false;

          for(let i=0;i<likedState?.length;i++){
            if(likedState[i]?.name===name){
              liked=true;
            }
          }
          
          let editedName=name.split('.');
          editedName.pop();
          editedName=editedName.join();
          
          let extension=name.split('.').pop();
          let isVideo=extension!=='jpeg'&&extension!=='jpg'&&extension!=='png'&&extension!=='gif'


          return(
           
              <div key={key} className='card'>
                  <div className='cardInner'>
                    {isVideo?<video loop={true} muted autoPlay={true} playsInline alt='' src={imageSrc} onClick={()=>{handleOpen(imageSrc,isVideo)}}/>:
                      <img alt='' src={imageSrc} onClick={()=>{handleOpen(imageSrc)}}/>
                    }
                    <div className='cardInner-bottom'>

                      <IconButton onClick={()=>{liked===false?likeMeme(name):dislikeMeme(name)}} sx={{ color: 'white' }}>
                        {liked?
                          <FavoriteIcon/>:
                          <FavoriteBorderIcon/>
                        }
                      </IconButton>
                      <span>{editedName}</span>
                    </div>
                </div>
              </div>
          
          )
      
        }
          
        )}
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {isVideo?<video muted className='modalImg' loop autoPlay playsInline alt='' src={selectedImg}/>:
              <img alt='' className='modalImg' src={selectedImg}/>
            }
            
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}

export default App
